<template>
  <div>
    <v-page-title title="Account">

    </v-page-title>
    <v-page-subtitle always-open>
      <div style="margin:auto; max-width:1160px;">
        <v-tabs v-model="tab" background-color="transparent" right height="72px">
          <v-tab>Details</v-tab>
          <v-tab>Profile</v-tab>
          <v-tab>Reports</v-tab>
          <v-tab>Integrations</v-tab>
          <v-tab>Logs</v-tab>
        </v-tabs>
      </div>
    </v-page-subtitle>

    <v-card max-width="1200px" elevation="0" color="transparent" class="mx-auto my-5">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-user-info></v-user-info>
              </v-col>
              <v-col cols="12" md="6">
                <v-account-info></v-account-info>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-account-contact></v-account-contact>
              </v-col>
              <v-col cols="12" md="6">
                <v-account-password></v-account-password>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-report-branding></v-report-branding>
              </v-col>
              <v-col cols="12" md="6">
                <v-footer-disclaimer></v-footer-disclaimer>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <v-row>
              <v-col>
                <v-integrations></v-integrations>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <v-row>
              <v-col>
                <v-users-logs></v-users-logs>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Account.vue',
    data() {
      return {
        tab: 0,
        dialogSlack: false,
        dialogGTM: false,
      }
    },
    computed:{
      user(){
        return this.$store.getters['users/user'];
      },
      meta(){
        return this.$store.getters['userMeta/get'];
      },
      sites(){
        return this.$store.getters['sites/all'];
      },
      scans(){
        return this.$store.getters['scans/all'];
      },
      credits(){
        return this.$store.getters['transactions/credits'];
      },
    },
  }
</script>

<style lang="scss" scoped>
  .company-logo-wrapper{
    border: solid 1px #ccc; 
    width: 200px; 
    display: flex; 
    align-content: center;
    margin: auto;
    img{
      max-height: 200px;
    }
  }
  .v-tabs-items{
    background-color: transparent;
  }
  table{
    tr{
      td{
        line-height: 1.5em;
        padding: 5px 0px;
      }
    }
  }
</style>